<template>
  <header class="d-none d-sm-block">
    <div class="nav-wrapper">
      <nav>
        <ul v-if="type === 'market'">
          <li>
            <router-link :to="{ name: `${market}.instruments`}">
              {{ $t('components.sub_header.charts') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: `${market}.video_analysis`}">
              {{ $t('components.sub_header.video_analysis') }}
            </router-link>
          </li>
          <!--<li>
            <a :href="env.calculator_app_url" target="_blank">
              {{ $t('components.sub_header.tools') }}
            </a>
          </li>-->
        </ul>

        <ul v-if="type === 'profile'">
          <li>
            <router-link :to="{ name: 'profile.settings'}">
              {{ $t('components.sub_header.settings') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'profile.payment_and_subscription'}">
              {{ $t('components.sub_header.payment_and_subscription') }}
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'profile.account'}">
              {{ $t('components.sub_header.account') }}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  props: ['type'],
  data () {
    return {
      env: {
        calculator_app_url: process.env.VUE_APP_CALCULATOR_APP_URL
      },
      market: null
    }
  },
  mounted: function () {
    this.market = this.$route.matched[0].meta.market
  }
}
</script>

<style scoped lang="scss">

header {
  height: 70px;
  border-bottom: 1px solid var(--ice-blue-charcoal-grey-three);
  background-color: var(--white-black);
  padding: 0 25px 0 30px;
  line-height: 68px;
  font-size: 14px;
  display: flex;

  .nav-wrapper {
    width: 100%;
  }
}

nav {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  li {
    display: inline-block;
    text-align: center;

    a {
      color: var(--charcoal-grey-white);
      display: block;
      padding: 0 44px;
      position: relative;

      @media (max-width: 991.98px) {
        padding: 0 18px;
      }
    }

    a:hover,
    a.router-link-active {
      color: var(--muted-blue-dark-sky-blue);
      text-decoration: none;
    }

    a:before,
    a:after {
      position: absolute;
      transition: all 0.35s ease;
    }

    a:before {
      bottom: 0;
      left: 0;
      display: block;
      height: 3px;
      width: 0%;
      content: '';
      background-color: var(--muted-blue-dark-sky-blue);
    }

    a:hover:before,
    a.router-link-active:before {
      opacity: 1;
      width: 100%;
    }

    a:hover:after,
    a.router-link-active:after {
      max-width: 100%;
    }

  }
}
</style>
